import Vue from 'vue'
import Vuex from 'vuex'
import http from '../utils/http'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    themeColor: '#4ACA6D', 
    hasLogin: false,
    userInfo: {},
    headerBtns: [
      {
        name: "注册/登录",
        link: "/login",
        icon: "",
      },
      {
        name: "个人中心",
        link: "/userInfo?num=3",
        icon: require('../assets/image/headman.png'),
      },
      {
        name: "课程搜索",
        link: "/search",
        icon: require('../assets/image/headbook.png'),
      },
      {
        name: "关注我们",
        link: "/about",
        icon: require('../assets/image/headheart.png'),
      },
      {
        name: "APP下载",
        link: "/appdownload",
        icon: require('../assets/image/app.png'),
      },
    ],
    isplay: false,
    provices: null,
    schools: null,
    grey:''
  },
  getters: {

  },
  mutations: {
    login(state, provider) {
      state.hasLogin = true;
      state.userInfo = provider.userInfo;
      window.sessionStorage.setItem('userInfo', JSON.stringify(provider.userInfo));
      if (provider.userInfo.token) {
        window.sessionStorage.setItem('token', provider.userInfo.token);
      }
    },
    provices(state, provider) {
      state.provices = provider.provices;
      window.localStorage.setItem('provices', JSON.stringify(provider.provices));
    },
    schools(state, provider) {
      state.schools = provider.schools;
      window.localStorage.setItem('schools', JSON.stringify(provider.schools));
    },
    setGrey(state, provider) {
      state.grey = provider.grey;
    },
    logout(state) {
      state.hasLogin = false;
      state.userInfo = {};
      window.sessionStorage.removeItem('userInfo');
      window.sessionStorage.removeItem('token');
    },
    topBtn(state) {
      state.headerBtns = [
        {
          name: "注册/登录",
          link: "/login",
          icon: "",
        },
        {
          name: "个人中心",
          link: "/userInfo?num=3",
          icon: "https://www.gaokao66.com/public/home/images/headman.png",
        },
        {
          name: "课程搜索",
          link: "/search",
          icon: require('../assets/image/headbook.png'),
        },
        {
          name: "关注我们",
          link: "/about",
          icon: "https://www.gaokao66.com/public/home/images/headheart.png",
        },
        {
          name: "APP下载",
          link: "/appdownload",
          icon: "https://www.gaokao66.com/public/home/images/app.png",
        },
      ];
      if (state.hasLogin) {
        state.headerBtns.splice(0, 1);
      } else {
        state.headerBtns.splice(1, 2);
      }
      // console.log(state.headerBtns)
    },
  },
  actions: {
    async getUserInfo({ commit, state }, payload) {
      if (!state.hasLogin) return;
      await http.post('api/students/userinfo')
        .then(res => {
          if (!res.data.data.card_number) {
            console.log(res.data.data)
            res.data.data.hasTime = false
          } else {
            let endTime = new Date(new Date(res.data.data.end_time).toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
            let time = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
            if (endTime < time) {
              res.data.data.hasTime = false
            } else {
              res.data.data.hasTime = true
            }

          }
          commit({
            type: 'login',
            userInfo: res.data.data
          })
        })
    },
    async getProvices({ commit, state }, payload) {
      if (!state.hasLogin) return;
      let prov = JSON.parse(window.localStorage.getItem('provices'))
      if (prov) {
        commit({
          type: 'provices',
          provices: prov
        })
      } else {
        await http.post('api/auth/address', { pid: 1 })
          .then(res => {
            // console.log(res)
            commit({
              type: 'provices',
              provices: res.data.data
            })

          })
      }

    },
    async getSchools({ commit, state }, payload) {
      if (!state.hasLogin) return;
      let id = window.localStorage.getItem('schoolId')
      if (!id) return;
      let prov = JSON.parse(window.localStorage.getItem('schools'))
      if (prov) {
        commit({
          type: 'schools',
          schools: prov
        })
      } else {
        await http.post('api/auth/school', { id: id })
          .then(res => {
            // console.log(res)
            commit({
              type: 'schools',
              schools: res.data.data
            })

          })
      }

    },
  },
  modules: {
  }
})
