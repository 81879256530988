import axios from "axios";
import {Message, MessageBox} from "element-ui";
import apiconfig from '../config/apiConfig'
//下面这两个不一定需要引入，看你项目需要拦截的时候做什么操作，但是一般都需要引入store
// import store from "@/store/index"; //引入store
// import router from "@/router/index"; //引入router
// let baseUrl = document.domain



const instance = axios.create({
  baseURL:  apiconfig.baseUrl,
  timeout: 1200000, // 请求超时时间2分钟
  // transformRequest: [
  // function(data) {
  //   console.log(data)
  //   let ret = "";
  //   for (let it in data) {
  //     ret +=
  //       encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
  //   }
  //   return ret;
  // }
  // ],
  withCredentials: true
});
instance.interceptors.request.use(
  config => {
    let token = window.sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    
    if (response.status >= 200 && response.status <= 299) {
      
      return response;
    }
  },
  error => {
    
    return Promise.reject(error);
  }
);
export default instance;
