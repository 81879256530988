import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./plugins/element.js";
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/global.css";
import "./common/animate.css"
import './assets/css/iconfont/iconfont.css'
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import ecsNodata from './components/nodata.vue'
import * as echarts from 'echarts'
import "@/assets/css/animate.css";
import axios from "../src/axios/index";
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios;
Vue.component('ecs-nodata', ecsNodata)
Vue.use(VideoPlayer)

Vue.config.productionTip = false;

// console.log = function () { }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
